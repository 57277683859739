import React from 'react';

export default function Moon({ color }) {
  return(
    <svg width="28px" height="24px" viewBox="0 0 28 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>night-symbol-of-the-moon-with-a-cloud-and-stars</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Mealtype" transform="translate(-146.000000, -305.000000)" fill={color} fillRule="nonzero">
          <g id="dinner" transform="translate(20.000000, 292.000000)">
            <g id="Group-2" transform="translate(0.000000, 13.000000)">
              <g id="night-symbol-of-the-moon-with-a-cloud-and-stars" transform="translate(126.000000, 0.000000)">
                <path d="M17.3568,11.30208 C15.27648,7.6992 15.42528,3.43008 17.3616,0.0671869638 C15.44928,0.06432 13.51104,0.53856 11.73504,1.5648 C7.90848,3.77568 5.8848,7.89216 6.14592,12.02208 C7.05312,12.05184 7.91136,12.3744 8.60256,12.95232 C9.25632,12.58848 9.98016,12.40128 10.73472,12.40128 C12.744,12.40128 14.4816,13.75392 15.024,15.64512 C16.44096,16.08192 17.44032,17.3856 17.44032,18.92256 C17.44032,20.52768 16.3296,21.86976 14.83872,22.25088 C17.51232,22.86624 20.41536,22.52064 22.97952,21.04128 C24.75552,20.016 26.13504,18.57504 27.08928,16.91616 C23.20896,16.90944 19.43712,14.904 17.3568,11.30208 Z" id="Path"></path>
                <path d="M16.49856,18.92064 C16.49856,17.61792 15.4992,16.56 14.22912,16.44192 C14.00928,14.69664 12.5376,13.34208 10.73472,13.34208 C9.87264,13.34208 9.09504,13.65984 8.48064,14.1696 C7.90368,13.43232 7.01568,12.94944 6.00768,12.94944 C4.50144,12.94944 3.24768,14.0064 2.93376,15.41664 C1.296,15.53088 0,16.88064 0,18.54816 C0,20.28768 1.4112,21.69792 3.15168,21.69792 C3.52128,21.69792 3.87264,21.62304 4.20192,21.50592 C4.776,22.29312 5.69856,22.81056 6.74688,22.81056 C7.38816,22.81056 7.98336,22.61664 8.4816,22.28736 C9.04992,22.8384 9.82176,23.18208 10.67712,23.18208 C11.93568,23.18208 13.0128,22.43808 13.51776,21.37056 C13.67232,21.40032 13.83072,21.42048 13.99488,21.42048 C15.37728,21.42144 16.49856,20.30112 16.49856,18.92064 Z" id="Path"></path>
                <polygon id="Path" points="23.36928 12.69696 23.81856 11.70816 24.88704 11.51904 24.0864 10.78464 24.2352 9.70848 23.29152 10.24512 22.3152 9.77088 22.53312 10.83552 21.78048 11.61696 22.85856 11.73984"></polygon>
                <polygon id="Path" points="1.79328 10.03968 2.56128 9.64512 3.3216 10.05312 3.18336 9.2016 3.8064 8.60448 2.95392 8.472 2.5776 7.69536 2.18976 8.4672 1.3344 8.58144 1.94784 9.19008"></polygon>
                <polygon id="Path" points="19.23744 8.11584 19.58784 8.77152 19.896 8.0928 20.62848 7.96416 20.07936 7.46112 20.18208 6.72384 19.53408 7.09056 18.864 6.76512 19.01376 7.49568 18.49728 8.0304"></polygon>
                <polygon id="Path" points="22.7664 6.16704 22.98144 6.57024 23.17056 6.15456 23.6208 6.07392 23.28384 5.7648 23.34528 5.3136 22.94784 5.54016 22.53984 5.33952 22.62912 5.78688 22.31232 6.1152"></polygon>
                <polygon id="Path" points="6.56448 3.45504 6.77952 3.85824 6.96864 3.4416 7.41888 3.36096 7.08192 3.05184 7.14432 2.60064 6.74688 2.82624 6.33696 2.62656 6.42816 3.07296 6.1104 3.40224"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

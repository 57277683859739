import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import QuoteContext from '../../context/quote-context';
import Meal from '../../components/GetQuoteSteps/meal';

export default function QuoteMealPage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <Meal set={quote.set} meal={quote.meal} />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
